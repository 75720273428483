export const ignoredModules = new Set([
  'child_process',
  'cluster',
  'inspector',
  'punycode',
  'readline',
  'repl',
  'tty',
  'v8',
  'vm',
  'wasi',
  'worker_threads'
]);
